import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';
import queryBuilder from '@glittr/frontend-core/src/plugins/validation/queryBuilder';

export default {
  ...queryBuilder.properties({
    accommodationTypeId: { type: 'string', required: true },
    isSeasonal: { type: 'boolean', required: true },
    countOfRooms: { type: 'integer', required: true },
    countOfBeds: { type: 'integer' },
  }),
} as IJSONSchema;
