import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';
import queryBuilder from '@glittr/frontend-core/src/plugins/validation/queryBuilder';
import validationPatterns from '@glittr/frontend-core/src/plugins/validation/validationPatterns';

export default {
  ...queryBuilder.properties({
    businessContactCeoSalutation: { type: 'integer', required: true },
    businessContactPersonFirstName: { type: 'string', required: true },
    businessContactPersonLastName: { type: 'string', required: true },
    businessContactPersonSalutation: { type: 'integer', required: true },
    businessContactCeoFirstName: { type: 'string', required: true },
    businessContactCeoLastName: { type: 'string', required: true },
    languageIso2: { type: 'string', required: true },
  }),
  properties: {
    businessContact: {
      type: 'object',
      properties: {
        address: {
          ...queryBuilder.properties({
            companyName: { type: 'string', required: true },
            street: { type: 'string', required: true },
            houseNumber: { type: 'string', required: true },
            postalCode: { type: 'string', required: true },
            city: { type: 'string', required: true },
            eMail: { type: ['string', 'null'], pattern: validationPatterns.email },
            countryIso2: { type: 'string', required: true },
          }),
        },
      },
    },
    billingContact: {
      ...queryBuilder.properties({
        isDifferentFromOwner: { type: 'boolean', required: true },
      }),
      allOf: [
        {
          ...queryBuilder.if({ isDifferentFromOwner: true }),
          then: {
            properties: {
              address: {
                ...queryBuilder.properties({
                  salutation: { type: 'number', required: true },
                  firstName: { type: 'string', required: true },
                  lastName: { type: 'string', required: true },
                  street: { type: 'string', required: true },
                  houseNumber: { type: 'string' },
                  postalCode: { type: 'string', required: true },
                  city: { type: 'string', required: true },
                  addressLine1: { type: 'string' },
                  poBox: { type: 'string' },
                  countryIso2: { type: 'string', required: true },
                }),
              },
            },
          },
        },
      ],
    },
  },
} as IJSONSchema;
