











import Vue from 'vue';
import { StepData } from '@/src/components/processes/rs-wizard.vue';
import CreateHotelClassificationRequestViewModel from '@/src/services/viewModel/resource/CreateHotelClassificationRequestViewModel';
import oneSchema from './schema/1.schema';
import twoSchema from './schema/2.schema';
import threeSchemaEdit from './schema/3.schema-edit';
import threeSchemaCreate from './schema/3.schema-create';
import fourSchema from './schema/4.schema';
import fiveSchema from './schema/5.schema';
import sixSchema from './schema/6.schema';
import sevenSchema from './schema/7.schema';

export default Vue.extend({
  auth: true,
  hasBackButton: true,
  data: () => ({
    memberShip: '',
    title: 'page.classifyHotel.page.title',
    model: CreateHotelClassificationRequestViewModel,
    steps: [
      {
        breadcrumb: 'page.classifyHotel.label.stepTitles.1',
        component: () => import('./classifyHotel1.vue'),
        schema: oneSchema,
        sendData: false,
      },
      {
        breadcrumb: 'page.classifyHotel.label.stepTitles.2',
        component: () => import('./classifyHotel2.vue'),
        schema: twoSchema,
        sendData: false,
      },
      {
        breadcrumb: 'page.classifyHotel.label.stepTitles.3',
        component: () => import('./classifyHotel3-edit.vue'),
        schema: threeSchemaEdit,
        sendData: false,
        // if params have contactId
        condition: () => Vue.$router.currentRoute.params.id !== undefined,
      },
      {
        breadcrumb: 'page.classifyHotel.label.stepTitles.3',
        component: () => import('./classifyHotel3-create.vue'),
        schema: threeSchemaCreate,
        sendData: false,
        // if params dont have contactId
        condition: () => Vue.$router.currentRoute.params.id === undefined,
      },
      {
        breadcrumb: 'page.classifyHotel.label.stepTitles.4',
        component: () => import('./classifyHotel4.vue'),
        schema: fourSchema,
        sendData: false,
      },
      {
        breadcrumb: 'page.classifyHotel.label.stepTitles.5',
        component: () => import('./classifyHotel5.vue'),
        schema: fiveSchema,
        sendData: true,
        // TODO: Configure properly when Backend is available
        // sendData: (data: CreateHotelClassificationRequestViewModel) => !data.orderBasket?.isOnlinePayment,
      },
      /* {
        breadcrumb: 'page.classifyHotel.label.stepTitles.6',
        component: () => import('./classifyHotel7.vue'),
        schema: sixSchema,
        sendData: true,
        // TODO: Configure properly when Backend is available
        condition: (data: CreateHotelClassificationRequestViewModel) => data.orderBasket?.isOnlinePayment,
      }, */
      {
        breadcrumb: 'page.classifyHotel.label.stepTitles.7',
        component: () => import('./classifyHotel7.vue'),
        schema: sevenSchema,
        clearProgressOnMounted: true,
        disablePreviousSteps: true,
        sendData: false,
      },
    ] as StepData[],
  }),
  computed: {
    contactId() {
      return this.$route.params.id;
    },
  },
  methods: {
    async serviceFunction(wizardData: CreateHotelClassificationRequestViewModel) {
      await this.$service.api.hotelClassifications.addHotelClassification(wizardData);
      try {
        await this.$auth.refresh();
      } catch (error) {
        console.error('Unable to refresh authentication');
        console.error(error);
      }
    },
    initializeData() {
      const userLanguage = Vue.$translation.get().toLowerCase();
      const initializedModel = new CreateHotelClassificationRequestViewModel().fromDTO({
        languageIso2: userLanguage,
        billingContact: { languageIso2: userLanguage, address: {} },
        businessContact: { languageIso2: userLanguage, address: {} },
      } as Parameters<CreateHotelClassificationRequestViewModel['fromDTO']>[0]);
      return initializedModel;
    },
  },
});
